import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import MiniHeroSection from "../MiniHeroSection/MiniHeroSection";
import {
  TextRow,
  TextCol,
  MenuContainer,
  BackgroundContainer,
  ItemCard,
  FeatItemCard,
  Header,
  Title,
  Price,
} from "./MenuSection.elements";
import NewImage from "../../../assets/kids.jpg";

function KidsMenu() {
  return (
    <BackgroundContainer>
      <MenuContainer>
        <Header>Kid's Menu</Header>
        <TextRow>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Ziti or Spaghetti</Title>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    With Marinara <Price> $6.99</Price>
                  </ListGroupItem>
                  <ListGroupItem>
                    With Meatball <Price> $8.99</Price>
                  </ListGroupItem>
                  <ListGroupItem>
                    With Alfredo <Price> $9.99</Price>
                  </ListGroupItem>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Tenders with Fries</Title>
                <Price>$8.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Baked Ziti</Title>
                <Price>$8.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Baked Ravioli</Title>
                <Price>$8.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>10” Kid’s Cheese Pizza</Title>
                <ItemCard.Text>$1.50 each topping</ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
        </TextRow>
      </MenuContainer>
    </BackgroundContainer>
  );
}

export default KidsMenu;
