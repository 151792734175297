import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import BgImg from '../../../assets/TEST.jpg';

export const HeroContainer = styled.div`
    width:100%;
    height: 90vh;
    
    background: url(${BgImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;

    display: flex;
    
    justify-content: center;
    align-items: left;
    flex-direction: column;
    
    text-align:center;
    margin-top: -80px;
    max-height:900px;

`;

export const HeroRow = styled(Row)`
    display: flex;
    justify-content: center;
    max-width:100%;
`;

export const TextCol = styled(Col)`
    padding-left: 3rem;
    max-width: 1300px;
    margin-right: 2rem;
`

export const HeroH1 = styled.h1`
    font-family: 'Oleo Script', cursive;
    font-size: 54px;
    width: 600px;
    color: white;
    text-align: left;

    @media screen and (max-width: 1140px) {
        width: 400px;
    }
    @media screen and (max-width: 880px) {
        width: 250px;
    }

`;

export const Buttoncall = styled.a`
    display: flex;
    border-radius: 12px;
    padding: 0.5rem 0;
    width: 10rem;
    justify-content: center;
    //background: #3E080C;
    background: transparent;
    color: white;
    border: 2px solid white;
    font-weight: bold;
    //text-decoration: none;
    //border: 2px solid black;
    //transition: .5s;
    //color:white;

    :hover{
    border-radius: 12px;
    background-color: white;
    transition: .3s;
    transition-timing-function: ease-in;
    color:black;
    text-decoration: none;
    }
`;