import Pic1 from '../assets/galleryPics/pizzaToppings.jpg';
import Pic2 from '../assets/galleryPics/gallerypic1.jpg';
import Pic3 from '../assets/galleryPics/chickenParm.jpg';
import Pic4 from '../assets/galleryPics/hawaiianPizza.jpg';
import Pic5 from '../assets/galleryPics/calzone.jpg';
import Pic6 from '../assets/wings.jpg';
import Pic7 from '../assets/galleryPics/interiorWine.jpg';
import Pic8 from '../assets/galleryPics/gallerypic6.jpg';
import Pic9 from '../assets/galleryPics/pizzarose.jpg';
import Pic10 from '../assets/tortillini.jpg';
import Pic11 from '../assets/galleryPics/interiorDish.jpg';

export const photos = [
  {
    // DONE
    src: Pic7,
    width: 3,
    height: 4
  },

  // {
  //   src: Pic2,
  //   width: 1,
  //   height: 1
  // },
  {
    src: Pic5,
    width: 5.5,
    height: 4
  },
  {
    src: Pic4,
    width: 5,
    height: 4
  },


  {
    src: Pic6,
    width: 2.5,
    height: 3
  },
  {
    // DONE
    src: Pic1,
    width: 5,
    height: 3
  },

  {
    src: Pic3,
    width: 4,
    height: 3
  },
  {
    src: Pic8,
    width: 5,
    height: 4
  },
  {
    src: Pic9,
    width: 3.5,
    height: 3
  },
  // {
  //   src: Pic10,
  //   width: 5,
  //   height: 4
  // },
  {
    // DONE
    src: Pic11,
    width: 4,
    height: 3
  },

];
