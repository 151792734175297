// this is es6 modules nothing specfic from react.
import React from 'react';
// ReactDom.render is in reactDom
import ReactDom from 'react-dom';
import image1 from "./imageAbt1.jpg";
import '../../../App.css';
import {
    BackgroundAbout,
    PolaroidPicContainer,
    Pic1,
    Pic2,
    Pic3
} from './AboutUs.elements';

function AboutUs(){

    return (
    <article  style="background-color: red" >

        <BackgroundAbout>
            <PolaroidPicContainer>
                <Pic1>
                </Pic1>
                <Pic2>
                </Pic2>
                <Pic3>
                </Pic3>
            </PolaroidPicContainer>
        </BackgroundAbout>
    </article>
    );
}


export default AboutUs