import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import MiniHeroSection from "../MiniHeroSection/MiniHeroSection";
import {
  TextRow,
  TextCol,
  MenuContainer,
  BackgroundContainer,
  ItemCard,
  FeatItemCard,
  Price,
  FeatPrice,
  Title,
  Header,
  PizzaList,
} from "./MenuSection.elements";
import NewImage from "../../../assets/calzones.jpg";

// function CardCon(props) {
//     <ItemCard>
//         <ItemCard.Body>
//             <Title>props.Title</Title>
//             <ItemCard.Text>
//                 Our signature garlic butter spread over toasted bread.
//             </ItemCard.Text>
//             <$3.99</
//         </ItemCard.Body>
//     </ItemCard>
// }

function Calzones() {
  return (
    <BackgroundContainer>
      <MenuContainer>
        <Header>Calzones</Header>
        <TextRow>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Calzone</Title>
                <ItemCard.Text>
                  Folded pizza dough stuffed with ricotta and mozzarella cheese
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    Small <Price>$10.99 </Price>
                  </ListGroupItem>
                  <ListGroupItem>
                    Large <Price>$14.99</Price>
                  </ListGroupItem>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <FeatItemCard>
              <ItemCard.Body>
                <Title>Supreme Calzone </Title>
                <ItemCard.Text>
                  Our Calzone with sausage, pepperoni, black olives, mushrooms,
                  onions, and bell peppers
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <FeatPrice>
                    Small <Price>$14.99</Price>{" "}
                  </FeatPrice>
                  <FeatPrice>
                    Large <Price>$18.99</Price>{" "}
                  </FeatPrice>
                </ListGroup>
              </ItemCard.Body>
            </FeatItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Stromboli</Title>
                <ItemCard.Text>
                  Rolled pizza dough stuffed with mozzarella cheese and your
                  choice of toppings
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    Small <Price>$10.99</Price>
                  </ListGroupItem>
                  <ListGroupItem>
                    Large <Price>$14.99</Price>{" "}
                  </ListGroupItem>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Supreme Stromboli </Title>
                <ItemCard.Text>
                  Our Stromboli with sausage, pepperoni, black olives,
                  mushrooms, onions, and bell peppers
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    Small <Price>$14.99</Price>
                  </ListGroupItem>
                  <ListGroupItem>
                    Large <Price>$18.99</Price>{" "}
                  </ListGroupItem>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Philla Boli </Title>
                <ItemCard.Text>
                  Our Stromboli with steak, onions, mushrooms, and cheese
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    Small <Price>$11.99</Price>
                  </ListGroupItem>
                  <ListGroupItem>
                    Large <Price>$16.99</Price>{" "}
                  </ListGroupItem>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Meatball Roll </Title>
                <ItemCard.Text>
                  Our Stromboli filled with marinara sauce and our homemade
                  meatballs
                </ItemCard.Text>
                <Price>$10.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Sausage Roll </Title>
                <ItemCard.Text>
                  Our Stromboli filled with marinara sauce and sausage
                </ItemCard.Text>
                <Price>$10.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Roll</Title>
                <ItemCard.Text>
                  Our Stromboli filled with marinara sauce and diced fried
                  chicken
                </ItemCard.Text>
                <Price>$11.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Eggplant Roll </Title>
                <ItemCard.Text>
                  Our Stromboli filled with marinara sauce and fried eggplant
                </ItemCard.Text>
                <Price>$10.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
        </TextRow>
        <TextRow>
          <FeatItemCard>
            <FeatItemCard.Body>
              <Title>
                Add your favorite fresh toppings to any Calzone or Stomboli
              </Title>
              <FeatItemCard.Text>
                Anchovies, Artichoke, Bacon, Basil, Bell Peppers, Black Olives,
                Broccoli, Chicken
                <strong>*</strong>, Eggplant<strong>*</strong>, Extra Cheese,
                Garlic, Ham, Hot Peppers, Meatballs, Mushrooms, Onions,
                Pepperoni, Pineapple, Ricotta Cheese<strong>*</strong>, Sausage,
                Spinach, or Tomatoes
              </FeatItemCard.Text>
              <ListGroup className="list-group-flush">
                <FeatPrice>
                  Small<Price>$1.50</Price>
                </FeatPrice>
                <FeatPrice>
                  Large<Price> $2.50</Price>
                </FeatPrice>
              </ListGroup>
              <FeatItemCard.Text style={{ color: "#3E080Ca" }}>
                <strong>* Double charge for these toppings </strong>
              </FeatItemCard.Text>
            </FeatItemCard.Body>
          </FeatItemCard>
        </TextRow>
      </MenuContainer>
    </BackgroundContainer>
  );
}

export default Calzones;
