import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import MiniHeroSection from "../MiniHeroSection/MiniHeroSection";
import {
  TextRow,
  TextCol,
  MenuContainer,
  BackgroundContainer,
  ItemCard,
  FeatItemCard,
  Title,
  Price,
  FeatPrice,
  Header,
  SubHeader,
  Category,
} from "./MenuSection.elements";
import NewImage from "../../../assets/pasta.jpg";
import chickenFrancese from "../../../assets/menuPics/ChickenFrancese.jpg";
import chickenMarsala from "../../../assets/menuPics/ChickenMarsala.jpg";
import bakedRav from "../../../assets/menuPics/bakedRav.jpg";
import eggplantRoll from "../../../assets/menuPics/eggplantRollatini.jpg";
// function CardCon(props) {
//     <ItemCard>
//         <ItemCard.Body>
//             <Title>props.Title</Title>
//             <ItemCard.Text>
//                 Our signature garlic butter spread over toasted bread.
//             </ItemCard.Text>
//             <Price>$3.99</Price>
//         </ItemCard.Body>
//     </ItemCard>
// }

function Entrees() {
  return (
    <BackgroundContainer>
      <MenuContainer style={{ borderTop: "2px solid lightgrey" }}>
        <Header>Traditional Pastas</Header>
        <SubHeader>
          All pastas come with a house salad (upgrade to a Caesar for $1) and
          bread{" "}
        </SubHeader>
        <TextRow>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Stuffed Shells </Title>
                <ItemCard.Text>
                  Romano, ricotta, parmesan cheese stuffed into our jumbo shells
                  with our classic marinara sauce
                </ItemCard.Text>
                <Price>$13.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Baked Ziti</Title>
                <ItemCard.Text>
                  Ziti pasta with our marinara sauce, mozzarella, ricotta,
                  parmesan cheese baked to perfection
                </ItemCard.Text>
                <Price>$13.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Manicotti</Title>
                <ItemCard.Text>
                  Manicotti pasta stuffed with ricotta, mozzarella, parmesan
                  cheese covered in our marinara sauce
                </ItemCard.Text>
                <Price>$13.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <FeatItemCard>
              <ItemCard.Body>
                <Title>Baked Ravioli</Title>
                <ItemCard.Text>
                  Cheese or meat ravioli covered in our marinara sauce topped
                  with mozzarella cheese and then baked
                </ItemCard.Text>
                <Price>$13.99</Price>
              </ItemCard.Body>
            </FeatItemCard>
          </TextCol>
          <TextCol>
            <FeatItemCard>
              <ItemCard.Body>
                <Title>Torino’s Primavera </Title>
                <ItemCard.Text>
                  Our signature mix of onion, mushroom, broccoli, tomatoes, bell
                  peppers, basil and feta cheese
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <FeatPrice>
                    <Price>$13.99</Price>
                  </FeatPrice>
                  <FeatPrice>
                    With Chicken <Price>$17.99</Price>
                  </FeatPrice>
                  <FeatPrice>
                    With Shrimp <Price>$17.99</Price>
                  </FeatPrice>
                </ListGroup>
              </ItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Lasagna</Title>
                <ItemCard.Text>
                  Pasta layered with our marinara ground beef, ricotta,
                  parmesan, romano, and mozzarella cheese
                </ItemCard.Text>
                <Price>$14.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <ListGroup className="list-group-flush">
                  <Title>Ziti or Spaghetti</Title>
                  <FeatPrice>
                    Marinara <Price> $11.99</Price>
                  </FeatPrice>
                  <FeatPrice>
                    Meat Sauce <Price> $12.99</Price>
                  </FeatPrice>
                  <FeatPrice>
                    Meatball <Price> $13.99</Price>
                  </FeatPrice>
                  <FeatPrice>
                    Sausage <Price> $13.99</Price>
                  </FeatPrice>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Penne Ala Vodka </Title>
                <ItemCard.Text>
                  Penne pasta with sautéed onion and bacon bits in our creamy
                  vodka sauce
                </ItemCard.Text>
                <ListGroup>
                  <FeatPrice>
                    <Price> $14.99 </Price>{" "}
                  </FeatPrice>
                  <FeatPrice>
                    With Chicken <Price> $18.99 </Price>{" "}
                  </FeatPrice>
                </ListGroup>{" "}
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Sausage, Peppers & Onions Over Pasta </Title>
                <ItemCard.Text>
                  Our sausage, peppers and onions over your choice of pasta and
                  marinara
                </ItemCard.Text>
                <Price>$13.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Tortellini</Title>
                <ItemCard.Text>
                  Your choice of cheese or meat stuffed ring pasta with red
                  sauce.
                </ItemCard.Text>
                <ListGroup>
                  <FeatPrice>
                    Alfredo Sauce <Price> $15.99 </Price>{" "}
                  </FeatPrice>
                  <FeatPrice>
                    Marinara <Price> $13.99 </Price>{" "}
                  </FeatPrice>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Pasta with Alfredo Sauce </Title>
                <ItemCard.Text>
                  Your choice of pasta with our creamy alfredo sauce
                </ItemCard.Text>
                <ListGroup>
                  <FeatPrice>
                    <Price> $13.99 </Price>{" "}
                  </FeatPrice>
                  <FeatPrice>
                    With Chicken <Price> $17.99 </Price>{" "}
                  </FeatPrice>
                  <FeatPrice>
                    With Shrimp <Price> $17.99 </Price>{" "}
                  </FeatPrice>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
        </TextRow>
      </MenuContainer>

      <MenuContainer>
        <Header>Entrees</Header>
        <SubHeader>
          Entrees are served with your choice of pasta, a house salad (upgrade
          to a Caesar for $1) and bread{" "}
        </SubHeader>
        <TextRow>
          <TextCol>
            <Category>Chicken</Category>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Parmigiana </Title>
                <ItemCard.Text>
                  Hand breaded chicken fried and topped with our marinara sauce
                  and melted mozzarella cheese
                </ItemCard.Text>
                <Price>$15.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Cacciatore </Title>
                <ItemCard.Text>
                  Fresh garlic, green peppers, mushrooms, fresh basil in our
                  marinara sauce
                </ItemCard.Text>
                <Price>$15.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Marsala</Title>
                <ItemCard.Text>
                  Fresh sautéed mushrooms with our homemade marsala wine sauce
                </ItemCard.Text>
                <Price>$15.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <FeatItemCard>
              <ItemCard.Body>
                <Title>Chicken Francese </Title>
                <ItemCard.Text>
                  Hand breaded chicken with eggs and flour, white wine lemon
                  sauce
                </ItemCard.Text>
                <Price>$15.99</Price>
                <FeatItemCard.Img src={chickenFrancese}></FeatItemCard.Img>
              </ItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Piccata</Title>
                <ItemCard.Text>
                  Sautéed chicken with our white wine lemon sauce topped with
                  capers
                </ItemCard.Text>
                <Price>$15.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Mushroom & Artichoke</Title>
                <ItemCard.Text>
                  Seared chicken covered in sautéed mushrooms and artichokes
                  with our white wine sauce
                </ItemCard.Text>
                <Price>$16.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Silva</Title>
                <ItemCard.Text>
                  Sautéed mushrooms with our signature alfredo-marsala mix
                </ItemCard.Text>
                <Price>$16.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <Category>Seafood</Category>
            <ItemCard>
              <ItemCard.Body>
                <Title>Shrimp Marinara </Title>
                <ItemCard.Text>
                  Sautéed shrimp with our classic marinara sauce over your
                  choice of pasta
                </ItemCard.Text>
                <Price>$16.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Shrimp Fra Diavolo</Title>
                <ItemCard.Text>
                  Sautéed shrimp with our spicy red sauce over your choice of
                  pasta
                </ItemCard.Text>
                <Price>$16.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Shrimp Scampi</Title>
                <ItemCard.Text>
                  Sautéed shrimp with our lemon wine sauce over your choice of
                  pasta
                </ItemCard.Text>
                <Price>$16.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Ling with Clam Sauce</Title>
                <ItemCard.Text>
                  Linguini pasta with clams, garlic, basil and your choice of
                  marinara or our white wine sauce
                </ItemCard.Text>
                <Price>$16.99</Price>
              </ItemCard.Body>
            </ItemCard>

            <Category style={{ paddingTop: "45px" }}>Eggplant</Category>
            <ItemCard>
              <ItemCard.Body>
                <Title>Eggplant Parmigiana</Title>
                <ItemCard.Text>
                  Fried eggplant topped with marinara sauce and melted
                  mozzarella cheese
                </ItemCard.Text>
                <Price>$14.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <FeatItemCard>
              <ItemCard.Body>
                <Title>Eggplant Rollatini </Title>
                <ItemCard.Text>
                  Fried eggplant rolled up with our ricotta cheese stuffing
                </ItemCard.Text>
                <Price>$15.99</Price>
              </ItemCard.Body>
            </FeatItemCard>
          </TextCol>
          <TextCol>
            <Category>Veal</Category>
            <ItemCard>
              <ItemCard.Body>
                <Title>Veal Parmigiana</Title>
                <ItemCard.Text>
                  Hand breaded veal fried and topped with our marinara sauce and
                  melted mozzarella cheese
                </ItemCard.Text>
                <Price>$16.49</Price>
              </ItemCard.Body>
            </ItemCard>
            <FeatItemCard>
              <ItemCard.Body>
                <Title>Veal Marsala</Title>
                <ItemCard.Text>
                  Fresh sautéed mushrooms with our marsala wine sauce
                </ItemCard.Text>
                <Price>$16.49</Price>
                <FeatItemCard.Img src={chickenMarsala}></FeatItemCard.Img>
              </ItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Veal Piccata</Title>
                <ItemCard.Text>
                  Sautéed veal with our white wine lemon sauce topped with
                  capers
                </ItemCard.Text>
                <Price>$16.49</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Veal Francese</Title>
                <ItemCard.Text>
                  Hand breaded veal with eggs and flour, white wine lemon sauce
                </ItemCard.Text>
                <Price>$16.49</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Veal Silva</Title>
                <ItemCard.Text>
                  Sautéed mushrooms with our signature alfredo-marsala mix
                </ItemCard.Text>
                <Price>$16.49</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
        </TextRow>
      </MenuContainer>
    </BackgroundContainer>
  );
}

export default Entrees;
