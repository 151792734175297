import React from 'react';
// ReactDom.render is in reactDom
import ReactDom from 'react-dom';

import {
    DLContainer,
    Dream,
    Legacy,
    Text,
    Pic4,
    Text1,
    Pic5,
    Pdream,
    Plegacy,
    FillBlack
} from './AbtLegacy.elements';

function LegaD(){

    return(

        <article>

            <DLContainer>
                <Dream>
                    <Pic4 alt = "an image of the exterior of the restaurant"/>
                    <Text>
                        Our Dream
                    </Text>   
                    <Pdream>
                        Torino’s Pizza Oviedo was a longtime dream of 
                        Toni Shehu and her husband Arben who immigrated 
                        from Europe in 1997. 
                    </Pdream>    
                </Dream> 
                <Legacy>
                    <Pic5 alt = "an image of the foodie award won by Torino's pizza in 2020" />
                    <Text>
                        Our Legacy
                    </Text>
                    <Plegacy>
                        Toni’s legacy begins cooking with her mother, in Europe. 
                        She had been making dishes 
                        from scratch and knew that she will have 
                        her own restaurant one day.
                    </Plegacy>  
                </Legacy>
            </DLContainer>
            </article>
    );


}


export default LegaD;