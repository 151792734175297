import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container } from '../../globalStyles';
import TorinosLogo from '../../assets/Torinos_Logo.png';


export const FooterContainer = styled(Container)`

    background: #222b13;
    max-width:100%;

    .footer-middle{
        padding-top: 1rem;
        background: #222b13;
        color: white;
        justify-content:space-evenly;

    }

    .footer-bottom {
        padding-top: .5rem;
        padding-bottom: 1px;
        color: lightgray;

    }

    .content {
        color: lightgray;
        font-size: 120%;
        
    
        
        
    }
    .row{
        justify-content: space-between;
        @media screen and (max-width:800px) {
        flex-direction:column !important;
        align-items:center !important;
        padding-left: -10px !important;
        
        
    }
    }

    .social {
        margin: 8px;
        margin-top: 5px;
        

    }

    .title {
        padding-top: 12px;
        font-weight: bold;
    }

    .socialHolder {
        justify-content:center;
        align-content: center;
    }

    .map {
        padding-top: 15px;
        padding-right:5%;
        @media screen and (max-width: 900px){
            padding-left: -5%;
            padding-right:5%;
            transition: 0.8 all ease;
        }
        
        
    }
`;