import React from 'react';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import { Navbar, Footer } from './components';
import HomePage from './pages/HomePage'
import AboutPage from './pages/AboutPage'
import MenuPage from './pages/MenuPage'
import ScrollToTop from './components/ScrollToTop';



function App(){
  return (
    <Router>
      <GlobalStyle />
      <ScrollToTop />
      <Navbar />
      <Switch>
        <Route path="/" exact component={HomePage}/>
        <Route path="/about" exact component={AboutPage}/>
        <Route path="/menu" exact component={MenuPage}/>
      </Switch>
      <Footer />
      
    </Router>
  )
}

export default App;
