import React from "react";
import Slider from "react-slick";
import fiveStar from './5stars.png';


export default function SimpleSlider() {
    var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5500,
    cssEase: "linear",
    variableWidth: false
    
    
    };
    return (
        <div className = "Whole">
            <h1 style = {{fontFamily: "'Oleo Script', cursive"}}>Testimonials</h1>
                <Slider {...settings} >
                    <div className = "contSlide">
                        <div className = "contText">
                            <p className="quote">
                                "This is our favorite place in Orlando to get a pizza. The food is always excellent and the service tonight from the waitress was exceptional.
                                She was very polite and helpful. Dine here with confidence knowing the food is great and the service is top notch."
                            </p>
                            <p className="quoteAuthor">
                                - Sasha T.
                            </p>
                            <img src={fiveStar} className="fiveStar" />
                        </div>
                    </div>
                    <div className = "contSlide">
                        <div className = "contText">
                            <p className="quote">
                                "Didn't know what to expect trying Torino's for the first time, but it totally exceeded our expectations! Clean and tidy inside, friendly service, high-quality ingredients.
                                You can tell the owner cares about his craft. Awesome local place!"
                            </p>
                            <p className="quoteAuthor">
                                - Ryan B.
                            </p>
                            <img src={fiveStar} className="fiveStar" />
                        </div>
                    </div>
                    <div className = "contSlide">
                        <div className = "contText">
                            <p className="quote">
                                "Excellent! I come here often and I love it! One of the best pizzas in the Orlando area. The service is very fast and the people working there are very polite.
                                I highly recommend this place for everyone especially for families."
                            </p>
                            <p className="quoteAuthor">
                                - Florian A.
                            </p>
                            <img src={fiveStar} className="fiveStar" />
                        </div>
                    </div>
                </Slider>
            <h3 style = {{fontFamily: "'Oleo Script', cursive"}}>Tell Us About Your Experience!</h3>
        </div>
    );
}