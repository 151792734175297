import React from 'react';
import {
    MenuContainer, BtnGroup, MenuBtn
} from './Menu.elements';
import {
    Route,
    NavLink,
    HashRouter
} from "react-router-dom";

import Appetizers from "../MenuSection/Appetizers"
import Beverages from "../MenuSection/Beverages"
import Calzones from "../MenuSection/Calzones"
import Desserts from "../MenuSection/Desserts"
import Heroes from "../MenuSection/Heroes"
import KidsMenu from "../MenuSection/KidsMenu"
import Pastas from "../MenuSection/Pastas"
import Pizzas from "../MenuSection/Pizzas"
import Salads from "../MenuSection/Salads"
import Specials from "../MenuSection/Specials"

function Menu() {
    return (
        // BAR
        <HashRouter>
            <MenuContainer className="main-nav">
                {/* // GROUP  */}
                <BtnGroup>
                    <NavLink to="/">
                        <MenuBtn>
                            <span>SPECIALS</span>
                        </MenuBtn>
                    </NavLink>
                    <NavLink to="/appetizer">
                        <MenuBtn>
                            <span>APPETIZERS</span>
                        </MenuBtn>
                    </NavLink>
                    <NavLink to="/salad">
                        <MenuBtn>
                            <span>SALADS</span>
                        </MenuBtn>
                    </NavLink>
                    <NavLink to="/pizza">
                        <MenuBtn>
                            <span>PIZZAS</span>
                        </MenuBtn>
                    </NavLink>
                    <NavLink to="/calzone">
                        <MenuBtn>
                            <span>CALZONES</span>
                        </MenuBtn>
                    </NavLink>
                    <NavLink to="/pastas">
                        <MenuBtn>
                            <span>PASTAS</span>
                        </MenuBtn>
                    </NavLink>
                    <NavLink to="/hero">
                        <MenuBtn>
                            <span>HEROES</span>
                        </MenuBtn>
                    </NavLink>
                    <NavLink to="/kidsmenu">
                        <MenuBtn>
                            <span>KID'S MENU</span>
                        </MenuBtn>
                    </NavLink>
                    <NavLink to="/beverage">
                        <MenuBtn>
                            <span>BEVERAGES</span>
                        </MenuBtn>
                    </NavLink>
                    <NavLink to="/dessert">
                        <MenuBtn>
                            <span>DESSERTS</span>
                        </MenuBtn>
                    </NavLink>
                </BtnGroup>

                <div>
                    <Route exact path='/' component={Specials} />
                    <Route path='/appetizer' component={Appetizers} />
                    <Route path='/pizza' component={Pizzas} />
                    <Route path='/calzone' component={Calzones} />
                    <Route path='/pastas' component={Pastas} />
                    <Route path='/salad' component={Salads} />
                    <Route path='/hero' component={Heroes} />
                    <Route path='/kidsmenu' component={KidsMenu} />
                    <Route path='/dessert' component={Desserts} />
                    <Route path='/beverage' component={Beverages} />
                </div>

            </MenuContainer>
        </HashRouter>


    )
}

export default Menu;