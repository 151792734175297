import styled from 'styled-components';
import Woman from './woman.jpg';
import Man from './man.jpg';
export const SleekContainer = styled.div`
    background-color: #3E080C;
    margin-top: 0px;
    width:100%;
    height: auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, .6);
    font-family: Montserrat Alternates;
`;

export const SleekPicContainer = styled.div`
    margin-top: 75px;
    display:flex;
    justify-content: center;
`;
export const SleekPics = styled.img.attrs({

    src: Man
})`
    width: 13vw;
    height: 29vh;
    border-radius: 50%;
    padding-right:10px;
    margin-top: 30px;
    @media screen and (max-width: 960px){
        width:30vw;
        height: 30vh;
        align-items: center;
        justify-content: center;
}
    
`;
export const SleekPics1 = styled.img.attrs({

    src: Woman
})` 
    width: 13vw;
    height: 29vh;
    border-radius: 50%;
    padding-left:10px;
    margin-top: 30px;
                    @media screen and (max-width: 960px){
        width:30vw;
        height: 30vh;
        align-items: center;
        justify-content: center;
}

`;

export const SleekTextContainer = styled.div`
    margin-top: 60px;
    display:flex;
    flex-direction:column;
    justify-content: center;
    margin-bottom: 100px;
    align-items:center;
        @media screen and (max-width: 960px){
        width:100%;
        height: 60vh; 
    
}  
    
`;

export const SleekText = styled.p`
    font-family: 'Raleway', sans-serif;
    justify-content: center;
    text-align:center;
    margin-top: 30px;
    width:52%;
    color: #fff;    
    line-height: 2em;
    font-size: 20px;
        @media screen and (max-width: 960px){
        width:100%;
        height: 35vh;
        align-items: center;
        justify-content: center;
        margin-bottom:25px;
        margin-top:15px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom:5px;  
        font-size:13px;     
}  

`;

export const SleekHeader = styled.h1`
    border-top:2px solid #fff;
    font-weight:bold;
    color: #fff;
    font-family: 'Raleway', sans-serif;
    justify-content: center;
    text-align:center;
    line-height: 2em;
    text-shadow: 1px 1px 4px rgba(255, 255, 255, 0.3);

    

`;
