import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Container } from '../../../globalStyles';


export const MenuContainer = styled(Container)`

    background: #3E080C;
    /* border: 1px solid red; */
    justify-content: center;
    max-width:100%;
    padding-top:20px;
    padding-bottom:50px;
    padding-left: 10%;
    padding-right:10%;
    font-size: 115%;

    @media screen and (max-width:1414px) {
        padding-left: 7%;
        padding-right: 7%;
        
    }
    @media screen and (max-width:600px) {
        padding-left: 5%;
        padding-right: 5%;
        transition: ease 0.8s;
    }


`;



export const MenuBtn = styled(Button)`
    transition: all 0.2s;
    position: relative;
    background: transparent;
    border: none;

    :focus {
        border: none !important;
        background-color: transparent !important;
        box-shadow: none !important;
        
        
    }

    :hover {
        background: transparent;
    }

    :before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.2s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: rgba(255,255,255,0.5);
    border-bottom-color: rgba(255,255,255,0.5);
    transform: scale(0.1, 1);
    }
    :hover span {
    letter-spacing: 2px;
    }
    :hover::before {
    opacity: 1;
    transform: scale(1, 1);
    }
    :after { // button bg
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.2s;
    background-color: rgba(255,255,255,0.1);
    
    }
    :hover::after {
    opacity: 0;
    transform: scale(0.1, 1);
    background-color: transparent;
    }

    :active {
        border: none !important;
        background-color: transparent !important;
        background-color: yellow;
        outline-color: red;
        
        
    }


`;

export const BtnGroup = styled.div`

    text-align: center;
    padding-bottom: 1em;


`;
