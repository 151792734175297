import styled from 'styled-components';


export const MiniHeroContainer = styled.div`
    background-image: url(${props=>props.img});
    //background-attachment: fixed;
    background-position:50% 60%;
    background-size: cover;
    width:100%;
    height: 40vh;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align:center;

`;

export const MiniHeroH1 = styled.h1`
    font-size: 1500em;
    color: #3E080C;
    padding: 15px;
    
    min-width: 500px;
    text-align: center;
    font-weight: bold;


    
`;
