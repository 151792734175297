import React from 'react';
import Gallery from "react-photo-gallery";
import { photos } from "../../../assets/galleryPhotos";
import {
    GalleryContainer, GalleryRow
} from './PhotoGallery.elements';


function PhotoGallery() {
    return (
        <GalleryRow>
            <GalleryContainer>
                <Gallery photos={photos} alt = "a gallery of food pictures."/>
            </GalleryContainer>
        </GalleryRow>

    )
}

export default PhotoGallery;