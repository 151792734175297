import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import MiniHeroSection from "../MiniHeroSection/MiniHeroSection";
import {
  TextRow,
  TextCol,
  MenuContainer,
  BackgroundContainer,
  ItemCard,
  FeatItemCard,
  Price,
  FeatPrice,
  Title,
  Header,
} from "./MenuSection.elements";
import NewImage from "../../../assets/salad.jpg";
import house from "../../../assets/menuPics/houseSalad.jpg";

// function CardCon(props) {
//     <ItemCard>
//         <ItemCard.Body>
//             <Title>props.Title</Title>
//             <ItemCard.Text>
//                 Our signature garlic butter spread over toasted bread.
//             </ItemCard.Text>
//             <ItemCard.Subtitle><Price>$3.99</Price></ItemCard.Subtitle>
//         </ItemCard.Body>
//     </ItemCard>
// }

function Salads() {
  return (
    <BackgroundContainer>
      <MenuContainer>
        <Header>Salads</Header>
        <TextRow>
          <TextCol>
            <FeatItemCard>
              <FeatItemCard.Body>
                <Title>House salad </Title>
                <FeatItemCard.Text>
                  Iceberg lettuce topped with tomatoes, onions, and black olives
                </FeatItemCard.Text>
                <ListGroup className="list-group-flush">
                  <FeatPrice>
                    Small <Price>$5.99</Price>
                  </FeatPrice>
                  <FeatPrice>
                    Large <Price>$8.99</Price>
                  </FeatPrice>
                </ListGroup>
                <FeatItemCard.Img src={house}></FeatItemCard.Img>
              </FeatItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Fresh Mozzarella and Tomato</Title>
                <ItemCard.Text>
                  Fresh slices of mozzarella and tomatoes
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Antipasta salad </Title>
                <ItemCard.Text>
                  Our signature house topped with fresh salami, ham and cheese
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    Small <Price>$7.99</Price>
                  </ListGroupItem>
                  <ListGroupItem>
                    Large <Price>$10.99</Price>
                  </ListGroupItem>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Greek salad</Title>
                <ItemCard.Text>
                  Our signature house salad topped with feta cheese and kalamata
                  olives
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    Small <Price>$7.99</Price>
                  </ListGroupItem>
                  <ListGroupItem>
                    Large <Price>$10.99</Price>
                  </ListGroupItem>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Caesar Salad </Title>
                <ItemCard.Text>
                  Romaine lettuce with our shredded parmesan cheese and croutons
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    Small <Price>$6.49</Price>
                  </ListGroupItem>
                  <ListGroupItem>
                    Large <Price>$9.49</Price>
                  </ListGroupItem>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
        </TextRow>
        <TextRow
          style={{
            border: "2px solid black",
            backgroundColor: "#FEF2E4",
            borderRadius: "5px",
          }}
        >
          <ItemCard
            style={{
              borderBottom: "none",
              backgroundColor: "#FEF2E4",
            }}
          >
            <ItemCard.Body>
              <Title>Add Chicken to any Salad</Title>
              <ListGroup className="list-group-flush">
                <ListGroupItem style={{ backgroundColor: "#FEF2E4" }}>
                  Small <Price>$4.00</Price>
                </ListGroupItem>
                <ListGroupItem style={{ backgroundColor: "#FEF2E4" }}>
                  Large <Price>$6.00</Price>
                </ListGroupItem>
              </ListGroup>
            </ItemCard.Body>
          </ItemCard>
          <ItemCard
            style={{
              borderBottom: "none",
              backgroundColor: "#FEF2E4",
            }}
          >
            <ItemCard.Body>
              <Title>Add Shrimp to any Salad</Title>
              <ListGroup className="list-group-flush">
                <ListGroupItem style={{ backgroundColor: "#FEF2E4" }}>
                  Small <Price>$4.00</Price>
                </ListGroupItem>
                <ListGroupItem style={{ backgroundColor: "#FEF2E4" }}>
                  Large <Price>$6.00</Price>
                </ListGroupItem>
              </ListGroup>
            </ItemCard.Body>
          </ItemCard>
        </TextRow>
      </MenuContainer>
    </BackgroundContainer>
  );
}

export default Salads;
