import React, { useState, useEffect } from "react";
import { NavLink } from "react-bootstrap";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";

import {
    Nav,
    NavbarContainer,
    NavLogo,
    MobileIcon,
    NavMenu,
    NavItem,
    NavLinks,
    NavSlice,
    NavDiv,
} from "./Navbar.elements";

const Navbar = ({ toggle }) => {
    const [scrollNav, setScrollNav] = useState(false);

    const changeNav = () => {
        if (window.scrollY >= 80) {
            setScrollNav(true);
        } else {
            setScrollNav(false);
        }
    };

    const [click, setClick] = useState(false);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    useEffect(() => {
        window.addEventListener("scroll", changeNav);
    }, []);

    return (
        <IconContext.Provider value={{ color: "#fff" }}>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavLogo
                        to="/"
                        onClick={closeMobileMenu}
                        alt="an approsthrophe shaped like a pizza in Torino's Pizza"
                    ></NavLogo>
                    <MobileIcon onClick={handleClick}>
                        {click ? <FaTimes /> : <FaBars />}
                    </MobileIcon>
                    <NavMenu onClick={handleClick} click={click}>
                        <NavItem>
                            <NavLinks to="/">Home</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/about">About Us</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavLinks to="/menu">Menu</NavLinks>
                        </NavItem>
                        <NavItem>
                            <NavDiv
                                onClick={() => {
                                    window.scroll({
                                        top: document.body.offsetHeight,
                                        left: 0,
                                        behavior: "smooth",
                                    });
                                }}
                            >
                                Contact
                            </NavDiv>
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
    );
};

export default Navbar;
