import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Container } from '../../globalStyles';
import TorinosLogo from '../../assets/Torinos_Logo.png';


/*
Notes: @media makes code react(lol) when the screen becomes a certain width
it makes the hamburger menu
*/

//Navbar
export const Nav = styled.nav`
    background: ${( {scrollNav} ) => (scrollNav ? '#000000' : 'transparent')};
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 999;
    transition: background 0.3s;

    @media screen and (max-width: 960px) {
        transition: 0.8 all ease;
    }
`;

export const NavbarContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    height: 80px;

    ${Container}
`;

//"Logo" text
export const NavLogo = styled(Link)`
    background-image: url(${TorinosLogo});
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    width: 15pc;

`;

export const MobileIcon = styled.div`
    display: none;

@media screen and (max-width: 960px){
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform : translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
}
`

export const NavMenu = styled.ul`
    display: flex;
    list-style: none;

    //when clicking on hamburger menu
    @media screen and (max-width: 960px) {
        display:flex;
        flex-direction: column;
        width:50%;
        height: 100vh;
        position:absolute;
        left: ${({click}) => (click ? 0 : '-100%')};
        opacity: 1;
        transition: all 0.5s ease;
        background: #43050A;
        text-decoration: none;

        @media screen and (max-width: 425px){
            width: 65%;
        }
    }
`;

export const NavItem = styled.li`
height: 80px;
border-bottom: 2px solid transparent;
text-decoration: none;
font-weight: bold;
//Box around the nav links 
&:hover {
    text-decoration: none;
    background-color: white;
}

@media screen and (max-width: 960px){
    width: 100%;

    &:hover {
        border: none;
        text-decoration:none;
    }
}
`
export const NavLinks = styled(Link)`
font-family: 'Raleway', sans-serif;
color: #fff;
display: flex;
align-items: center;
padding: 0.5rem 1rem;
height: 100%;
z-index: 100;

&:hover {
        color: black;
        text-decoration: none;
        transition: all 0.3s ease;


    }

//While in hamburger menu
@media screen and (max-width: 960px) {
    text-align: left;
    padding: 2rem;
    width: 100%;
    display: table;
    font-size: 2rem;
    z-index: 100;
    &:hover {
        color: black;
        text-decoration: none;
        transition: all 0.3s ease;

    }
}
`

export const NavSlice = styled.a`
font-family: 'Raleway', sans-serif;
color: #fff;
display: flex;
align-items: center;
padding: 0.5rem 1rem;
height: 100%;
z-index: 100;

&:hover {
        color: black;
        text-decoration: none;
        transition: all 0.3s ease;


    }

//While in hamburger menu
@media screen and (max-width: 960px) {
    text-align: left;
    padding: 2rem;
    width: 100%;
    display: table;
    font-size: 2rem;
    z-index: 100;
    &:hover {
        color: black;
        text-decoration: none;
        transition: all 0.3s ease;

    }
}
`

export const NavDiv = styled.div`
font-family: 'Raleway', sans-serif;
color: #fff;
display: flex;
align-items: center;
padding: 0.5rem 1rem;
height: 100%;
cursor: pointer;

&:hover {
        color: black;
        text-decoration: none;
        transition: all 0.3s ease;


    }

//While in hamburger menu
@media screen and (max-width: 960px) {
    text-align: left;
    padding: 2rem;
    width: 100%;
    display: table;
        font-size: 2rem;
    &:hover {
        color: black;
        text-decoration: none;
        transition: all 0.3s ease;

    }
}
`