import styled from 'styled-components';
import { Col, Container, Row, Image } from 'react-bootstrap';
import image1 from '../../../assets/TORINOS_SIGN.jpg';
import award2 from '../../../assets/award2.jpg';

export const Containercall = styled(Container)`
    background-color: white;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 45px;
    padding-top: 45px;
    @media screen and (max-width: 960px){
        flex-direction: column;
    }
`;

export const RowCall = styled(Row)`
    display: flex;
    max-width: 1340px;
    justify-content: center;
`;

export const PicCol = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 960px){
    }
`;

export const Picbox = styled.div`
    background: url(${image1});
    background-size: cover;
    background-position: 25%;
    border-radius: 8px;
    height: 450px;
    width: 450px;
    @media screen and (max-width: 960px){
            height: 350px;
            width: 280px;
    }
`;

export const Textbox = styled(Col)`
    background-color: transparent;
    color: white;
    text-align: center;
    order: 2;
    margin: auto;
    width: 10%;
    padding: auto;
    flex: 1 0 15%;
    height: auto;
    @media screen and (max-width: 960px){
        width:100%;
        justify-content:center;
        align-items:center;
    }
    
`;
export const Headcall = styled.h1`
    font-family: 'Raleway', sans-serif;
    background-color: transparent;
    color: #3E080C;
    font-weight: bold;
    padding-bottom: 5px;
`;
export const Paracall = styled.p`

font-family: 'Raleway', sans-serif;

    background-color: transparent;
    font-size: 18px;
    color: black;
    @media screen and (max-width: 960px){
        font-size: 16px;
    }
`;

export const Buttoncall = styled.a`
    display: inline-block;
    border-radius: 12px;
    padding: 0.5rem 0;
    margin: 0.5rem 1rem;
    width: 11rem;
    //background: #3E080C;
    background: transparent;
    color: #3E080C;
    border: 2px solid #3E080C;

    :hover{
    border-radius: 12px;
    background-color: #3E080C;
    transition: .3s;
    transition-timing-function: ease-in;
    color: white;
    text-decoration: none;
    }
`;