import styled from 'styled-components';
import { Container } from '../../../globalStyles';

export const GalleryContainer = styled(Container)`
    /* border: 2px solid yellow; */
    /* background-color: red; */
    
    max-width: 1335px;
    padding-bottom: 45px;
    padding-top: 45px;
`;

export const GalleryRow = styled.div`
    background-color: #FEF2E4; // CREAM
    width: 100%;
`;