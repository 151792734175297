import React from "react";
import { ListGroup } from "react-bootstrap";
import MiniHeroSection from "../MiniHeroSection/MiniHeroSection";
import {
    TextRow,
    TextCol,
    MenuContainer,
    BackgroundContainer,
    ItemCard,
    FeatItemCard,
    Title,
    FeatPrice,
    Price,
    Header,
    PizzaList,
} from "./MenuSection.elements";
import NewImage from "../../../assets/specials.jpg";

function Pizzas() {
    return (
        <BackgroundContainer>
            <MenuContainer>
                <Header>Pizzas</Header>
                <TextRow>
                    <TextCol>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Cheese Pizza </Title>
                                <ItemCard.Text>
                                    Classic cheese pizza with our tomato sauce
                                    and mozzarella cheese
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Small 10”<Price>$9.99</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Medium 14” <Price>$13.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Large 16″<Price> $15.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        X-Large 18″<Price>$17.49</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Vegetable Pizza </Title>
                                <ItemCard.Text>
                                    Our tomato sauce, mozzarella cheese,
                                    spinach, broccoli, mushroom, onion, black
                                    olive, fresh garlic
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Small 10”<Price>$13.99</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Medium 14” <Price>$19.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Large 16″<Price> $21.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        X-Large 18″<Price>$25.49</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Spinach & Feta Cheese Pizza </Title>
                                <ItemCard.Text>
                                    Spinach, fresh garlic, feta, mozzarella and
                                    parmesan cheese
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Small 10”<Price>$12.99</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Medium 14” <Price>$16.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Large 16″<Price> $18.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        X-Large 18″<Price>$22.49</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Spinach & Tomato Pizza </Title>
                                <ItemCard.Text>
                                    Spinach, fresh tomatoes, fresh garlic,
                                    mozzarella and parmesan cheese
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Small 10”<Price>$12.99</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Medium 14” <Price>$16.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Large 16″<Price> $18.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        X-Large 18″<Price>$22.49</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>
                        <FeatItemCard>
                            <ItemCard.Body>
                                <Title>Sicilian Thick Crust Pizza</Title>
                                <ItemCard.Text>
                                    Create your own thick crust pizza by adding
                                    your own toppings
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <FeatPrice>
                                        Large 16” (12 slices){" "}
                                        <Price>$20.99</Price>
                                    </FeatPrice>
                                </ListGroup>
                            </ItemCard.Body>
                        </FeatItemCard>
                    </TextCol>
                    <TextCol>
                        <FeatItemCard>
                            <FeatItemCard.Body>
                                <Title>Torino’s Supreme Pizza</Title>
                                <ItemCard.Text>
                                    Our tomato sauce, sausage, pepperoni, black
                                    olives, mushrooms, onions, bell peppers and
                                    mozzarella cheese
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <FeatPrice>
                                        Small 10”<Price>$13.99</Price>
                                    </FeatPrice>
                                    <FeatPrice>
                                        Medium 14” <Price>$19.49</Price>
                                    </FeatPrice>
                                    <FeatPrice>
                                        Large 16″<Price> $21.49</Price>
                                    </FeatPrice>
                                    <FeatPrice>
                                        X-Large 18″<Price>$25.49</Price>
                                    </FeatPrice>
                                </ListGroup>
                            </FeatItemCard.Body>
                        </FeatItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Meat Extreme Pizza </Title>
                                <ItemCard.Text>
                                    Our tomato sauce, sausage, meatball,
                                    pepperoni, bacon, ham and mozzarella
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Small 10”<Price>$13.99</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Medium 14” <Price>$19.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Large 16″<Price> $21.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        X-Large 18″<Price>$25.49</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>
                        <FeatItemCard>
                            <ItemCard.Body>
                                <Title>Margherita Pizza </Title>
                                <ItemCard.Text>
                                    Fresh tomato, fresh basil, fresh garlic
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <FeatPrice>
                                        Small 10”<Price>$12.99</Price>
                                    </FeatPrice>
                                    <FeatPrice>
                                        Medium 14” <Price>$16.49</Price>
                                    </FeatPrice>
                                    <FeatPrice>
                                        Large 16″<Price> $18.49</Price>
                                    </FeatPrice>
                                    <FeatPrice>
                                        X-Large 18″<Price>$22.49</Price>
                                    </FeatPrice>
                                </ListGroup>
                            </ItemCard.Body>
                        </FeatItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Hawaiian Pizza </Title>
                                <ItemCard.Text>
                                    Our tomato sauce, ham, pineapple, and
                                    mozzarella cheese
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Small 10”<Price>$12.99</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Medium 14” <Price>$16.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Large 16″<Price> $18.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        X-Large 18″<Price>$22.49</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Bruschetta Pizza</Title>
                                <ItemCard.Text>
                                    Fresh tomato, fresh basil, fresh garlic,
                                    feta cheese, olive oil
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Small 10”<Price>$12.99</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Medium 14” <Price>$17.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Large 16″<Price> $19.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        X-Large 18″<Price>$23.49</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>
                    </TextCol>
                    <TextCol>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Pizza by the Slice</Title>
                                <ItemCard.Text>
                                    Your choice of the town's favorite pizza by
                                    the slice
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Cheese <Price>$3.00</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        White <Price>$3.50</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Sicilian <Price> $3.50</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>

                        <ItemCard>
                            <ItemCard.Body>
                                <Title>BBQ Chicken Pizza </Title>
                                <ItemCard.Text>
                                    BBQ sauce, mozzarella cheese, topped with
                                    seasoned chicken
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Small 10”<Price>$12.99</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Medium 14” <Price>$16.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Large 16″<Price> $18.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        X-Large 18″<Price>$22.49</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>White Pizza </Title>
                                <ItemCard.Text>
                                    Fresh garlic, ricotta, mozzarella and
                                    parmesan cheese
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Small 10”<Price>$12.99</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Medium 14” <Price>$16.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Large 16″<Price> $18.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        X-Large 18″<Price>$22.49</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Meat Extreme Stuffed Pizza</Title>
                                <ItemCard.Text>
                                    Stuffed with pepperoni, sausage, ham, bacon,
                                    meatball and served with a side of marinara
                                    sauce
                                </ItemCard.Text>
                                <PizzaList style={{ border: "none" }}>
                                    Large 16” (12 slices) <Price>$31.99</Price>
                                </PizzaList>
                            </ItemCard.Body>
                        </ItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Vegetable Stuffed Pizza</Title>
                                <ItemCard.Text>
                                    Stuffed with spinach, broccoli, mushroom,
                                    onion,black olive, fresh garlic, tomato
                                    sauce and ricotta cheese
                                </ItemCard.Text>
                                <PizzaList style={{ border: "none" }}>
                                    Large 16” (12 slices) <Price>$28.99</Price>
                                </PizzaList>
                            </ItemCard.Body>
                        </ItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Baked Ziti Pizza</Title>
                                <ItemCard.Text>
                                    Our tomato sauce, ziti pasta, ricotta,
                                    mozzarella and parmesan cheese
                                </ItemCard.Text>
                                <ListGroup className="list-group-flush">
                                    <PizzaList>
                                        Small 10”<Price>$13.99</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Medium 14” <Price>$19.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        Large 16″<Price> $21.49</Price>
                                    </PizzaList>
                                    <PizzaList>
                                        X-Large 18″<Price>$25.49</Price>
                                    </PizzaList>
                                </ListGroup>
                            </ItemCard.Body>
                        </ItemCard>
                    </TextCol>
                </TextRow>
                <TextRow>
                    <FeatItemCard>
                        <FeatItemCard.Body>
                            <Title>
                                Add your favorite fresh toppings to any Pizza
                            </Title>
                            <FeatItemCard.Text>
                                Anchovies, Artichoke, Bacon, Basil, Bell
                                Peppers, Black Olives, Broccoli, Chicken
                                <strong>*</strong>, Eggplant<strong>*</strong>,
                                Extra Cheese, Garlic, Ham, Hot Peppers,
                                Meatballs, Mushrooms, Onions, Pepperoni,
                                Pineapple, Ricotta Cheese<strong>*</strong>,
                                Sausage, Spinach, or Tomatoes
                                <br />
                                10” Gluten Free pizza dough available upon
                                request
                            </FeatItemCard.Text>
                            <ListGroup className="list-group-flush">
                                <FeatPrice>
                                    Small 10”<Price>$1.50</Price>
                                </FeatPrice>
                                <FeatPrice>
                                    Medium 14” <Price>$2.00</Price>
                                </FeatPrice>
                                <FeatPrice>
                                    Large 16″<Price> $2.50</Price>
                                </FeatPrice>
                                <FeatPrice>
                                    X-Large 18″<Price>$3.00</Price>
                                </FeatPrice>
                            </ListGroup>
                            <FeatItemCard.Text style={{ color: "#3E080Ca" }}>
                                <strong>
                                    * Double charge for these toppings{" "}
                                </strong>
                            </FeatItemCard.Text>
                        </FeatItemCard.Body>
                    </FeatItemCard>
                </TextRow>
            </MenuContainer>
        </BackgroundContainer>
    );
}

export default Pizzas;
