// this is es6 modules nothing specfic from react.
import React from 'react';
// ReactDom.render is in reactDom
import ReactDom from 'react-dom';
// import css
import '../../../App.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.min.css'
import 'owl.carousel/dist/assets/owl.theme.default.min.css'
import {
    CarouselContainer,
} from './Carousel.elements';

import fiveStar from './5stars.png';

function Carousel() {
    return (
        <CarouselContainer className="CarouselContainer">
            <div className="header-container">
                <Header className="header" />
            </div>
            <OwlCarousel
                className="owl-theme"
                items="1"
                autoplay
                dots
                loop
                center
                autoplayTimeout="8000"
                autoHeightClass
                
                
                
            >
                <div className="item owl-height" alt = "a revolving set of quotes of Torino's pizza reviews found on Yelp">
                    <p className="quote">
                        "This is our favorite place in Orlando to get a pizza. The food is always excellent and the service tonight from the waitress was exceptional.
                        She was very polite and helpful. Dine here with confidence knowing the food is great and the service is top notch."
                    </p>
                    <p className="quoteAuthor">
                        - Sasha Ryan T.
                    </p>
                    <img src={fiveStar} className="fiveStar" />
                </div>
                <div className="item">
                    <p className="quote">
                        "Didn't know what to expect trying Torino's for the first time, but it totally exceeded our expectations! Clean and tidy inside, friendly service, high-quality ingredients.
                        You can tell the owner cares about his craft. Awesome local place!"
                    </p>
                    <p className="quoteAuthor">
                        - Ryan B.
                    </p>
                    <img src={fiveStar} className="fiveStar"/>
                </div>
                <div className="item">
                    <p className="quote">
                        "Excellent! I come here often and I love it! One of the best pizzas in the Orlando area. The service is very fast and the people working there are very polite.
                        I highly recommend this place for everyone especially for families."
                    </p>
                    <p className="quoteAuthor">
                        - Florian A.
                    </p>
                    <img src={fiveStar} className="fiveStar" />
                </div>
            </OwlCarousel>
            <div className="call">
                <p>Tell Us About Your Experience!</p>
            </div>
        </CarouselContainer>
    );
}

const Header = () => { return <h1 className="header">They Enjoyed It!</h1> };
export default Carousel;