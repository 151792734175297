import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import MiniHeroSection from "../MiniHeroSection/MiniHeroSection";
import {
  TextRow,
  TextCol,
  MenuContainer,
  BackgroundContainer,
  ItemCard,
  FeatItemCard,
  Header,
  Title,
  FeatPrice,
  Price,
} from "./MenuSection.elements";
import NewImage from "../../../assets/hero.jpg";

// function CardCon(props) {
//     <ItemCard>
//         <ItemCard.Body>
//             <Title>props.Title</Title>
//             <ItemCard.Text>
//                 Our signature garlic butter spread over toasted bread.
//             </ItemCard.Text>
//             <Price>$3.99</Price>
//         </ItemCard.Body>
//     </ItemCard>
// }

function Heroes() {
  return (
    <BackgroundContainer>
      <MenuContainer>
        <Header>Heroes</Header>
        <TextRow>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Ham and Cheese</Title>
                <ItemCard.Text>
                  Lettuce, tomato, onions, mayonnaise and our signature house
                  dressing
                </ItemCard.Text>
                <Price>$8.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Salami and Cheese </Title>
                <ItemCard.Text>
                  Lettuce, tomato, onions, mayonnaise and our signature house
                  dressing
                </ItemCard.Text>
                <Price>$8.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <FeatItemCard>
              <ItemCard.Body>
                <Title>Torino’s Special </Title>
                <ItemCard.Text>
                  Ham, salami, hot peppers, pepperoni, provolone cheese,
                  lettuce, tomato, onions, mayonnaise and our signature house
                  dressing
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Sausage Peppers and Onion </Title>
                <ItemCard.Text>
                  Homemade sausage topped with sautéed peppers and onions
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <FeatItemCard>
              <ItemCard.Body>
                <Title>Meatball Parmigiana </Title>
                <ItemCard.Text>
                  Our homemade meatballs with our classic marinara sauce, topped
                  with mozzarella cheese
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Parmigiana </Title>
                <ItemCard.Text>
                  Hand breaded fried chicken with our classic marinara sauce,
                  topped with mozzarella cheese
                </ItemCard.Text>
                <Price>$10.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Sausage Parmigiana </Title>
                <ItemCard.Text>
                  Sausage topped with our classic marinara sauce, topped with
                  mozzarella cheese
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Veal Parmigiana </Title>
                <ItemCard.Text>
                  Hand breaded then fried veal with our classic marinara sauce,
                  topped with mozzarella cheese
                </ItemCard.Text>
                <Price>$11.49</Price>
              </ItemCard.Body>
            </ItemCard>

            <ItemCard>
              <ItemCard.Body>
                <Title>Eggplant Parmigiana </Title>
                <ItemCard.Text>
                  Hand breaded then fried eggplant with our classic marinara
                  sauce, topped with mozzarella cheese
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Buffalo Chicken </Title>
                <ItemCard.Text>
                  Our crispy chicken fingers tossed with our hot buffalo sauce
                </ItemCard.Text>
                <Price>$10.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <FeatItemCard>
              <ItemCard.Body>
                <Title>Philly Cheese Steak </Title>
                <ItemCard.Text>
                  Classic Philly Cheese Steak topped with sautéed onions and
                  mushrooms
                </ItemCard.Text>
                <Price>$10.99</Price>
              </ItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Cheese Steak </Title>
                <ItemCard.Text>
                  Seasoned chicken with cheese with sautéed onions and mushrooms
                </ItemCard.Text>
                <Price>$10.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Gyro</Title>
                <ItemCard.Text>
                  Gyro meat, lettuce, tomato, onions, mayonnaise and our
                  signature house dressing
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
        </TextRow>
      </MenuContainer>
    </BackgroundContainer>
  );
}

export default Heroes;
