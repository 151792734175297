import styled from "styled-components";
import {
    Col,
    Row,
    Container,
    Card,
    ListGroupItem,
    Subtitle,
} from "react-bootstrap";

export const Title = styled(ListGroupItem)`
    font-weight: bold;
    border: none;
    align: left;
    padding-left: 0px;
    background: none;
    font-size: 25px;
    padding-bottom: 10px;
    padding-top: 0px;
    color: #3e080c;
    /* @media screen and (max-width: 1415px) {
        //font-size:125%;
    } */
`;
export const Price = styled(ListGroupItem)`
    font-weight: bold;
    border: none;
    text-align: right;
    padding-left: 0px;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    background: none;
    float: right;
    font-size: 18px;
    color: #3e080c;
    margin-bottom: 6px;
`;
export const FeatPrice = styled(ListGroupItem)`
    background: none;
    padding-bottom: 0px;
    float: left;
    padding-left: 0px;
    padding-right: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
`;

export const BackgroundContainer = styled.div`
    background-color: #ffffff;
    opacity: 90%;
    padding-bottom: 25px;
`;

export const MenuContainer = styled(Container)`
    background-color: none;

    //padding-left:5%;
    //padding-right:5%;

    /* @media screen and (max-width: 960px) {
        transition: 0.8 all ease;
        padding-left:10%;
        padding-right:10%;
    } */
`;

export const TextRow = styled(Row)`
    background: #ffffff;
    //border: 2px solid black;
    margin-top: 25px;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
`;
export const ComboRow = styled(Row)`
    background: #ffffff;
    //border: 2px solid black;
    margin-top: 25px;
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
    @media screen and (max-width: 1068px) {
        flex-direction: column;
    }
`;

export const TextCol = styled(Col)`
    background: #ffffff;
    padding: 30px;
    .priceBold {
        font-weight: bold;
        float: right;
    }

    @media screen and (max-width: 1430px) {
        padding: 1%;
    }
`;

export const ItemCard = styled(Card)`
    margin: 0 auto;
    margin-bottom: 0px;
    border: 0;
    padding: 0px;
    border-bottom: 1px solid lightgray;
    border-radius: 0;
`;

export const FeatItemCard = styled(Card)`
    margin: 0 auto;
    border: 2px solid black;
    margin-bottom: 20px;
    background-color: #fef2e4;
    box-shadow: 1.5px 1.5px lightgrey;

    @media screen and (max-width: 960px) {
        top: 0% !important;
    }
`;
export const Header = styled.h1`
    font-family: "Oleo Script", cursive;
    text-shadow: 2px 2px #fef2e4;
    font-size: 5rem;
    color: #3e080c;
    font-weight: bold;
    //min-width: 500px;
    max-width: 30%;
    text-align: center;
    justify-content: center;
    padding-bottom: 0px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    display: flex;
    margin-bottom: 0px;
    transition: ease 0.8s;

    @media screen and (max-width: 600px) {
        font-size: 325% !important;
        transition: ease 0.8s;
    }
    @media screen and (max-width: 400px) {
        font-size: 225% !important;
        transition: ease 0.8s;
    }
`;
export const SubHeader = styled.h2`
    font-size: 1.5rem;
    color: #3e080c;

    text-align: center;
    justify-content: center;

    margin-left: auto;
    margin-right: auto;

    display: flex;
    margin-bottom: 0px;
    @media screen and (max-width: 520px) {
        font-size: 70% !important;
    }
`;

export const ComboItemCard = styled(Card)`
    margin: 0 auto;
    border: 2px solid black;
    margin-bottom: 20px;
    background-color: #fef2e4;
    box-shadow: 1.5px 1.5px lightgrey;
    width: 18rem;
    height: 13rem;
    transition: ease 0.8s;

    @media screen and (max-width: 800px) {
        top: 0% !important;
        transition: ease 0.8s;
    }
    @media screen and (max-width: 340px) {
        top: 0% !important;
        transition: ease 0.8s;
        width: 13rem;
        height: 14rem;
    }
`;

export const PizzaList = styled(ListGroupItem)`
    padding-left: 0px;
    padding-right: 0px;
`;

export const Category = styled.h2`
    color: #3e080c;
    font-family: "Oleo Script", cursive;
    text-shadow: 2px 2px #fef2e4;
    text-align: center;
    border-bottom: 3px solid lightgray;
    font-size: 3rem;
    @media screen and (max-width: 800px) {
        padding: 13px !important;
    }
`;
