import styled from 'styled-components';
import hero from '../../../assets/HEROBG.jpg'


export const Shade = styled.div`
    margin-top:0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
`;

export const Background = styled.div`
    background: url(${hero});
    width:100%;
    height: 100%auto;
    margin-top:-80px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    justify-content: center;
    align-items: center;


`;


;