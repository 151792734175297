import React from 'react';
import Carousel from '../components/HomePageComponents/Carousel/Carousel'
import HeroSection from '../components/HomePageComponents/HeroSection/HeroSection'
import CallToAction from '../components/HomePageComponents/CallToAction/Call'
import PhotoGallery from '../components/HomePageComponents/Gallery/PhotoGallery'
import SimpleSlider from '../components/HomePageComponents/Carousel/SimpleSlider'



function HomePage(props) {

    return(
        <div>
            <HeroSection />
            <CallToAction />
            <PhotoGallery />
            {/* <Carousel /> */}
            <SimpleSlider/>   
        </div>
    )    

}

export default HomePage;