import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import MiniHeroSection from "../MiniHeroSection/MiniHeroSection";
import {
  TextRow,
  TextCol,
  MenuContainer,
  BackgroundContainer,
  ItemCard,
  FeatItemCard,
  Title,
  Price,
  Header,
} from "./MenuSection.elements";
import NewImage from "../../../assets/beverages.jpg";

// function CardCon(props) {
//     <ItemCard>
//         <ItemCard.Body>
//             <Title>props.Title</Title>
//             <ItemCard.Text>
//                 Our signature garlic butter spread over toasted bread.
//             </ItemCard.Text>
//             <Price>$3.99</Price>
//         </ItemCard.Body>
//     </ItemCard>
// }

function Beverages() {
  return (
    <BackgroundContainer>
      <MenuContainer>
        <Header>Beverages</Header>
        <TextRow>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Red Wine</Title>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    Chianti <p className="priceBold">$6.99</p>
                  </ListGroupItem>
                  <ListGroupItem>
                    Merlot <p className="priceBold">$6.99</p>
                  </ListGroupItem>
                  <ListGroupItem>
                    Cabernet Sauvignon
                    <p className="priceBold">$6.99</p>
                  </ListGroupItem>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>House Wine 1/2 Carafe</Title>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>White Wine</Title>
                <ListGroup className="list-group-flush">
                  <ListGroupItem>
                    Chardonnay <p className="priceBold">$6.75</p>
                  </ListGroupItem>
                  <ListGroupItem>
                    Pinot Grigio
                    <p className="priceBold">$6.75</p>
                  </ListGroupItem>
                  <ListGroupItem>
                    Moscato
                    <p className="priceBold">$6.99</p>
                  </ListGroupItem>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>House Wine by the Glass</Title>
                <Price>$5.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Beer, Domestic Bottle</Title>
                <Price>$4.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Beer, Imported Bottle</Title>
                <Price>$5.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
        </TextRow>
      </MenuContainer>
    </BackgroundContainer>
  );
}

export default Beverages;
