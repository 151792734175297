import styled from 'styled-components';
import image1 from './imageAbt1.jpg';

export const BackgroundAbout = styled.div`
    padding: 0px; 
    margin: 0px;
    background-color: #3e080c;
`;

export const PolaroidPicContainer = styled.div`
    width: 100%;
    display: flex; 
    flex-direction: row;
    padding-top: 80px;
    margin-top: -80px;
    align-items: center;
        justify-content: center;

        @media screen and (max-width: 960px){
        flex-direction: column;
        padding: 0px;
        width: 100%;
        display: flex; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
}
    

`;

export const Pic1 = styled.img.attrs({
    src: image1
})`
    background: #ffffff;
    display: inline-block;
    margin: 55px 75px 30px;
    padding: 15px 15px 30px;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -webkit-transition: all .20s linear;
    -moz-transition: all .20s linear;
    transition: all .20s linear;
    z-index: 0;
    position: relative;
    transform: rotate(-22deg);

:after {
    color: #333;
    font-size: 25px;
    position: relative;
    top: 15px;
}

    {
    display: block;
    width: 250px;
}

:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
    z-index: 10;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}
`;

export const Pic2 = styled.img.attrs({
    src: image1
})`
    background: #ffffff;
    display: inline-block;
    margin: 55px 75px 30px;
    padding: 15px 15px 30px;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -webkit-transition: all .20s linear;
    -moz-transition: all .20s linear;
    transition: all .20s linear;
    z-index: 0;
    position: relative;
    transform: rotate(-5deg);

:after {
    color: #333;
    font-size: 25px;
    position: relative;
    top: 15px;
    
}

    {
    display: block;
    width: 250px;
}

:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
    z-index: 10;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}
`;

export const Pic3 = styled.img.attrs({
    src: image1
})`
    background: #ffffff;
    display: inline-block;
    margin: 55px 75px 30px;
    padding: 15px 15px 30px;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -webkit-transition: all .20s linear;
    -moz-transition: all .20s linear;
    transition: all .20s linear;
    z-index: 0;
    position: relative;
    transform: rotate(23deg);

:after {
    color: #333;
    font-size: 25px;
    content: attr(title);
    position: relative;
    top: 15px;
}

    {
    display: block;
    width: 250px;
}

:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
    z-index: 10;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}
`;

