import styled from 'styled-components';
import SvgPizza from './pizza.svg';
import Build from './restar.svg';
import Plate from './plate.svg';
import int from './interi.jpg';
import rose from './rospla.jpg';
import award from '../../../assets/award.jpg'
import sign from '../../../assets/sign.jpg'




export const DLContainer = styled.div`
    background-color: #FEF2E5;
    width:100%;
    height: auto;
    display:flex;
    flex-direction: row;
    padding:0px;
    margin:0px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .8);
    

    padding-bottom: 20px;
            @media screen and (max-width: 960px){
        flex-direction: column;
        padding: 0px;
        width: 100%;
        display: flex; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
}

`;  

export const Dream= styled.div`
    width:50%;
    text-align:center;
    margin-left:100px;
        @media screen and (max-width: 960px){
        margin:0 auto; 
        width:100%               
}  

`;  



export const Pic4 = styled.img.attrs({
    src: sign
})`
    background: #ffffff;
    display: inline-block;
    margin: 55px 75px 30px;
    padding: 15px 15px 30px;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -webkit-transition: all .20s linear;
    -moz-transition: all .20s linear;
    transition: all .20s linear;
    z-index: 0;
    position: relative;


:after {
    color: #333;
    font-size: 25px;
    position: relative;
    top: 15px;
    
}

    {

    width: 350px;
}

:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
    z-index: 10;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}

    @media screen and (max-width: 960px){
        margin: 0 auto;
}  
`;
export const Text = styled.h1`
    font-family: 'Raleway', sans-serif;
    padding-bottom: 5px;
    padding-top: 5px; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
            @media screen and (max-width: 960px){
        margin-top: 50px;
}



`;

export const Pdream = styled.p`
    width:50%;
    text-align:center;
    height:auto;
    font-family: 'Raleway', sans-serif;
    margin: 0 auto;
    font-size:20px;
            @media screen and (max-width: 960px){
        margin: 0 auto;
        width:70%;
        align-items: center;
        justify-content: center;
        padding: 15px 0px 40px;
}
    

`;



export const Legacy= styled.div`
    width:50%;
    text-align:center;
    margin-right:100px;
        @media screen and (max-width: 960px){
        margin:0 auto; 
        width:100%;     
}  


`;  
export const Pic5 = styled.img.attrs({
    src: award
})`
    background: #ffffff;
    display: inline-block;
    margin: 55px 75px 30px;
    padding: 15px 15px 30px;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
    -webkit-transition: all .20s linear;
    -moz-transition: all .20s linear;
    transition: all .20s linear;
    z-index: 0;
    position: relative;


:after {
    color: #333;
    font-size: 25px;
    position: relative;
    top: 15px;
    
}

    {

    width: 350px;
}

:hover {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    transform: scale(1.2);
    z-index: 10;
    -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    -moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
    box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}

    @media screen and (max-width: 960px){
        margin: 0 auto;
}  

`; 



export const Plegacy = styled.p`
    font-size:20px;
    width:50%;
    text-align:center;
    height:auto;
    font-family: 'Raleway', sans-serif;
    margin: 0 auto;
    padding-bottom: 10px;
    @media screen and (max-width: 960px){
        width:70%;
        margin:0 auto;
        padding: 15px 0px 40px;
}
`;