import styled from 'styled-components';
import { Container } from '../../../globalStyles';

export const CarouselContainer = styled(Container)`

    max-width:100%;
    box-sizing: border-box;
    padding-left: 15%;
    padding-right: 15%;
    padding-top:15px;
    background-color:#3E080C;
    padding-bottom:0;

    .header{
        background-color:#3E080C;
    }
    .header-container{
        background-color:#3E080C;
    }
    .item{
        font-size:24px;
        color: #ffffff;
        text-align: center;
        width:50%;
        margin:0 auto;
        font-style: italic;
        margin-top:5px;

    }
    .owl-theme {
        //box-sizing: border-box;
        background-color:rgba(254, 242, 228,.6);
        margin-top:5px;

    }
    
    .call {
        background-color:#3E080C;
        /* background-color:red; */

        font-weight: 500;
        font-size: 18px;
        padding-top: 20px;
        padding-bottom: 1px;


    }

    .quote{
        padding-top: 6%;
        text-align:center;
        font-weight: 600;
        font-size:150%;
    }

    .quoteAuthor {
        text-align:end;
        font-weight:bold;
        float: right;
        padding-bottom: 8%;
    }

    .fiveStar {
        height:auto; 
        width:auto;
        max-width:28%;
        max-height:28%;
        min-width:30%;
        float: inline-start;
        margin-left: 15px;
    }


`;
