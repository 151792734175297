import styled from 'styled-components';
import BgImg from '../../../assets/menuNew.jpg';

export const HeroContainer = styled.div`
    //width:100%;
    height: 50vh;
    background: url(${BgImg});
    background-repeat: no-repeat;
    background-position: 30% 50%;
    background-size:cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align:center;
    margin-top: -80px;


`;

export const HeroH1 = styled.div`
    font-size: 60px;
    font-family: 'Oleo Script', cursive;
    color: white;
    text-decoration:none;
    
`;
