import React from 'react';
import HeroSection from '../components/MenuComponents/HeroSection/HeroSection'
import Menu from '../components/MenuComponents/Menu/Menu'


function MenuPage(props) {

    return(
        <div>
            <HeroSection/>
            <Menu/>
        </div>
    );

}

export default MenuPage;