import styled from 'styled-components';
import Torinos from './Torinos.jpg'

export const ContainerFancy = styled.div`
width: 100%; 
height: 100vh;
margin: 0px;
display:flex;
flex-direction: row;
padding: 80px;


background-image: repeating-radial-gradient(circle at center center, transparent 0px, 
    transparent 2px,rgba(0,0,0,0.03) 2px, rgba(0,0,0,0.03) 3px,transparent 3px, 
    transparent 5px,rgba(0,0,0,0.03) 5px, rgba(0,0,0,0.03) 7px),repeating-radial-gradient(circle at center center, 
    rgb(254,242,228) 0px, rgb(254,242,228) 9px,rgb(254,242,228) 9px, rgb(254,242,228) 21px,rgb(254,242,228) 21px, rgb(254,242,228) 
    31px,rgb(254,242,228) 31px, rgb(254,242,228) 40px); 
    background-size: 20px 20px;

            @media screen and (max-width: 960px){
        flex-direction: column;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height:auto;
        display: flex; 
        flex-direction: column;
        align-items: center;
        justify-content: center;
}


`;

export const LeftContainer = styled.div`
width:50%;
background-color:#F8F3EC;
box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, .3);
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
margin-right:20px;


            @media screen and (max-width: 960px){
        flex-direction: column;
        padding: 0px;
        width: 100%;
        height:auto;
        display: flex; 
        flex-direction: column;   
}



`;

export const FancyText = styled.p`
    font-family: 'open-sans';
    font-size: 1.3em;
    text-align: center;
    width: 70%;
    height:50%;
    
    
`;

export const ImgurCont = styled.div`
    position:relative;
    top: -70px;
    width: 65%;
    height: 45%;
    background-color:#F8F3EC;
    margin-top: 20px;
    box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, .3);
    display: flex;
    flex-direction: column;
    align-items: center;
    
    justify-content: center

                    @media screen and (max-width: 960px){
        padding: 0px;
        width: 100%;
        height:auto;
        display: flex; 
        flex-direction: column;   
}
    
    
`;

export const RightContainer = styled.div`
    background-color:#F8F3EC;
    box-shadow: 0px 4px 6px 3px rgba(0, 0, 0, .3);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-left:20px;
    width:50%;

                @media screen and (max-width: 960px){
        padding: 0px;
        width: 100%;
        height:auto;
        display: flex; 
        flex-direction: column;   
}

    

`;
export const Imgur= styled.img.attrs({
    src: Torinos

}

)`
    
    width:90%;
    height:90%;
    
                @media screen and (max-width: 960px){
        padding: 0px;
        width: 100%;
        height:auto;
        display: flex; 
        flex-direction: column; 

`;
