import {HeroContainer, HeroH1, HeroRow, TextCol, Buttoncall} from './HeroSection.elements'

function HeroSection ()
{
    return (
        <HeroContainer alt = " the background is a picture of a pizza">
            <HeroRow>
                <TextCol>
                    <HeroH1>Authentic Italian Cuisine Since 2012</HeroH1>
                    <Buttoncall href='../../menu' alt = 'a button to take you to the menu'>
                        Our Menu
                    </Buttoncall>
                </TextCol>
            </HeroRow>
        </HeroContainer>
    );
}

export default HeroSection;