import React from 'react';
import About from '../components/AboutPageComponents/aboutUs/About';
import LegaD from '../components/AboutPageComponents/Legacy/AbtLegacy';
import EuroCenter from '../components/AboutPageComponents/Europe/Euro';
import Fancy from '../components/AboutPageComponents/fancypart/fancy'
import Sleek from '../components/AboutPageComponents/Legacy/Sleek';
import HeroSection from '../components/AboutPageComponents/HeroSection/HeroSection'

function AboutPage(props) {

    return(
        <div>
            <HeroSection/>
            <LegaD/>
            <Sleek/>
            
        </div>
    );

}

export default AboutPage;