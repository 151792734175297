import react from 'react';
import {HeroContainer, HeroH1} from './HeroSection.elements'

// WORK IN PROGRESS
function HeroSection ()
{
    return (
        <HeroContainer>
            <HeroH1>About Us</HeroH1>
        </HeroContainer>
    );
}

export default HeroSection;