import react from 'react';
import {MiniHeroContainer, MiniHeroH1} from './MiniHeroSection.elements';

// WORK IN PROGRESS
function MiniHeroSection (props)
{
    return (
        <div>
        {/* <MiniHeroContainer img = {props.img} >
            
        </MiniHeroContainer> */}
        <MiniHeroH1>
                <h1>{props.text}</h1>
            </MiniHeroH1>
        </div>
    );
}

export default MiniHeroSection;