import React from 'react';

import {
    SleekContainer,
    SleekPics,
    SleekPics1,
    SleekText,
    SleekPicContainer,
    SleekTextContainer,
    SleekHeader
} from './Sleek.elements';

function Sleek(){

    return(
        <div>
            <SleekContainer>
                {/* <SleekPicContainer>
                    <SleekPics alt = "a protrait picture of a male who is the owner of Torinos Pizza">

                    
                    </SleekPics > 
                    <SleekPics1 alt = "a protrait picture of a female who is the  second owner of Torinos Pizza">

                    </SleekPics1> 
                </SleekPicContainer> */}
                    <SleekTextContainer>
                        <SleekHeader>
                            Our Story
                        </SleekHeader>
                        <SleekText>
                            We have been serving customers in the Oviedo area since January 2012 and we continue to serve our customers with quality food that everyone can enjoy. Torino’s prides itself on using the finest ingredients, made from scratch recipes, and casual Italian cuisine that you are certain to love.
                            At Torino’s Pizza Oviedo, we are proud to serve only the finest Italian dishes. Some of our most popular dishes that you have to try are our Homemade Lasagna as well as our mouthwatering Chicken Parmesan and Chicken Alfredo. 
                            We even offer takeout/delivery and catering, so you always have our food on the go.
                            So come on in and be part of the family, you won’t be disappointed.
                        </SleekText>
                </SleekTextContainer>
            </SleekContainer>
        </div>
    );

}


export default Sleek;