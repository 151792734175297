import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import MiniHeroSection from "../MiniHeroSection/MiniHeroSection";
import {
  TextRow,
  TextCol,
  MenuContainer,
  BackgroundContainer,
  ItemCard,
  FeatItemCard,
  Price,
  Title,
  FeatPrice,
  Header,
} from "./MenuSection.elements";
import garlicKnots from "../../../assets/garlicKnots.jpg";
import loadedFries from "../../../assets/loadedFries.jpg";

// function CardCon(props) {
//     <ItemCard>
//         <ItemCard.Body>
//             <Title>props.Title</Title>
//             <ItemCard.Text>
//                 Our signature garlic butter spread over toasted bread.
//             </ItemCard.Text>
//             <Price >$3.99</Price>
//         </ItemCard.Body>
//     </ItemCard>
// }

function Appetizers() {
  return (
    <BackgroundContainer>
      <MenuContainer>
        <Header>Appetizers</Header>
        <TextRow>
          <TextCol>
            <FeatItemCard>
              <FeatItemCard.Body>
                <Title>Garlic Knots</Title>
                <FeatItemCard.Text>
                  Six twisted knots coated in our signature garlic butter
                </FeatItemCard.Text>
                <Price>$4.99</Price>
                <FeatItemCard.Img src={garlicKnots}></FeatItemCard.Img>
              </FeatItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Wings</Title>
                <ItemCard.Text>
                  Your choice of Mild, Medium, Hot, BBQ, or Teriyaki
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <FeatPrice>
                    10 Wings <Price>$13.99</Price>
                  </FeatPrice>
                  {/* <FeatPrice>
                                        20 Wings <Price>$21.99</Price>
                                    </FeatPrice>
                                    <FeatPrice>
                                        30 Wings <Price>$32.99</Price>
                                    </FeatPrice> */}
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Mozzarella Sticks </Title>
                <ItemCard.Text>
                  Six breaded sticks of mozzarella fried until golden brown
                </ItemCard.Text>
                <Price>$8.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Garlic Bread</Title>
                <ItemCard.Text>
                  Our signature garlic butter spread over toasted bread
                </ItemCard.Text>
                <Price>$4.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Garlic Bread with Cheese </Title>
                <ItemCard.Text>
                  Our toasted garlic bread with our special blend of cheeses
                </ItemCard.Text>
                <Price>$5.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Zesty Tenders </Title>
                <ItemCard.Text>
                  Chicken tenders fried and then tossed in your choice of Mild,
                  Medium, Hot, BBQ, or Teriyaki
                </ItemCard.Text>
                <Price>$10.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Tenders </Title>
                <ItemCard.Text>
                  Breaded chicken fried until golden brown
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Jalapeno Poppers </Title>
                <ItemCard.Text>
                  Six stuffed jalapenos fried to perfection
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Bruschetta </Title>
                <ItemCard.Text>Tomatoes and mozzarella cheese</ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Fried Calamari </Title>
                <ItemCard.Text>
                  Hand breaded squid fried until crispy
                </ItemCard.Text>
                <Price>$11.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>French Fries</Title>
                <ItemCard.Text>
                  Classic crispy french fries made fresh with a touch of salt
                  and pepper
                </ItemCard.Text>
                <Price>$4.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <FeatItemCard>
              <ItemCard.Body>
                <Title>Loaded French Fries </Title>
                <ItemCard.Text>
                  French fries loaded with cheese and bacon bits
                </ItemCard.Text>
                <Price>$6.99</Price>
              </ItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Soup of the Day </Title>
                <ItemCard.Text>
                  Ask about our fresh made soup of the day
                </ItemCard.Text>
                <Price>$5.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
        </TextRow>
      </MenuContainer>
    </BackgroundContainer>
  );
}

export default Appetizers;
