import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import MiniHeroSection from "../MiniHeroSection/MiniHeroSection";
import {
  TextRow,
  TextCol,
  MenuContainer,
  BackgroundContainer,
  ItemCard,
  FeatItemCard,
  Header,
  Title,
  Price,
  FeatPrice,
  SubHeader,
  ComboItemCard,
  ComboRow,
} from "./MenuSection.elements";
import NewImage from "../../../assets/yes.jpg";
// function CardCon(props) {
//     <ItemCard>
//         <ItemCard.Body>
//             <Title>props.Title</Title>
//             <ItemCard.Text>
//                 Our signature garlic butter spread over toasted bread.
//             </ItemCard.Text>
//             <Price>$3.99</Price>
//         </ItemCard.Body>
//     </ItemCard>
// }

function Specials() {
  return (
    <BackgroundContainer>
      <MenuContainer>
        <Header>Daily Combos</Header>
        <SubHeader>Pick-Up & Delivery Only</SubHeader>
        <ComboRow>
          <TextCol>
            <ComboItemCard>
              <FeatItemCard.Body>
                <Title>Combo # 1</Title>
                <FeatItemCard.Text>
                  Large One Topping Pizza, Order of Garlic Knots & 2 Liter Soda
                </FeatItemCard.Text>
                <Price>$23.99</Price>
              </FeatItemCard.Body>
            </ComboItemCard>
            <ComboItemCard>
              <FeatItemCard.Body>
                <Title>Combo # 2</Title>
                <FeatItemCard.Text>
                  X-Large Cheese Pizza, Order of Garlic Knots & 10 Wings
                </FeatItemCard.Text>
                <Price>$32.99</Price>
              </FeatItemCard.Body>
            </ComboItemCard>
          </TextCol>
          <TextCol>
            <ComboItemCard style={{ top: "25%" }}>
              <FeatItemCard.Body>
                <Title>Combo # 3</Title>
                <FeatItemCard.Text>
                  2 Large Cheese Pizzas & 10 Wings
                </FeatItemCard.Text>
                <Price>$39.99</Price>
              </FeatItemCard.Body>
            </ComboItemCard>
          </TextCol>
          <TextCol>
            <ComboItemCard>
              <FeatItemCard.Body>
                <Title>Combo # 4</Title>
                <FeatItemCard.Text>
                  Large Cheese Pizza, 10 Wings & 2 Liter Soda
                </FeatItemCard.Text>
                <Price>$29.99</Price>
              </FeatItemCard.Body>
            </ComboItemCard>
            <ComboItemCard>
              <FeatItemCard.Body>
                <Title>Combo # 5</Title>
                <FeatItemCard.Text>
                  Large Cheese Pizza, Medium Cheese Pizza & 2 Liter of Soda
                </FeatItemCard.Text>
                <Price>$28.99</Price>
              </FeatItemCard.Body>
            </ComboItemCard>
          </TextCol>
        </ComboRow>
      </MenuContainer>

      <MenuContainer style={{ borderTop: "2px solid lightgrey" }}>
        <Header>Lunch Specials</Header>
        <SubHeader>11:00 AM - 3:00 PM Monday - Friday</SubHeader>
        <TextRow>
          <TextCol>
            <FeatItemCard>
              <FeatItemCard.Body>
                <Title>Slice of Cheese Pizza & House Salad</Title>
                <Price>$7.99</Price>
              </FeatItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Soup of the day & House Salad</Title>
                <Price>$8.49</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Lasagna</Title>
                <ItemCard.Text>
                  Pasta layered with our marinara ground beef, ricotta,
                  parmesan, romano, and mozzarella cheese
                </ItemCard.Text>
                <Price>$10.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Ziti or Spaghetti</Title>
                <ListGroup className="list-group-flush">
                  <FeatPrice>
                    Marinara <Price>$7.99</Price>
                  </FeatPrice>
                  <FeatPrice>
                    Meat Sauce <Price>$8.99</Price>
                  </FeatPrice>
                  <FeatPrice>
                    Meatball <Price>$8.99</Price>
                  </FeatPrice>
                  <FeatPrice>
                    Sausage <Price>$8.99</Price>
                  </FeatPrice>
                </ListGroup>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Baked Ziti </Title>
                <ItemCard.Text>
                  Ziti pasta with our marinara sauce, mozzarella, ricotta,
                  parmesan cheese baked to perfection
                </ItemCard.Text>
                <Price>$8.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Baked Ravioli</Title>
                <ItemCard.Text>
                  Cheese or meat ravioli covered in our marinara sauce topped
                  with mozzarella cheese and then baked
                </ItemCard.Text>
                <Price>$8.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <ItemCard>
              <ItemCard.Body>
                <Title>Eggplant Parmigiana </Title>
                <ItemCard.Text>
                  Fried eggplant topped with marinara sauce and melted
                  mozzarella cheese served with a side of spaghetti
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Parmigiana </Title>
                <ItemCard.Text>
                  Hand breaded chicken fried and topped with our marinara sauce
                  and melted mozzarella cheese served with a side of spaghetti
                </ItemCard.Text>
                <Price>$10.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Tortellini </Title>
                <ItemCard.Text>
                  Cheese tortellini with our marinara sauce
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>2 Manicotti</Title>
                <ItemCard.Text>
                  Manicotti pasta stuffed with ricotta, mozzarella, parmesan
                  cheese covered in our marinara sauce
                </ItemCard.Text>
                <Price>$8.99</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>2 Stuffed Shells </Title>
                <ItemCard.Text>
                  Romano, ricotta, parmesan cheese stuffed into our jumbo shells
                  with our classic marinara sauce
                </ItemCard.Text>
                <Price>$8.99</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
          <TextCol>
            <FeatItemCard>
              <FeatItemCard.Body>
                <Title>2 Slices of Cheese Pizza & a Soft Drink</Title>
                <Price>$7.50</Price>
              </FeatItemCard.Body>
            </FeatItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>½ Cold Sub & French Fries </Title>
                <ItemCard.Text>
                  Your choice of ½ a cold sub and crispy French fires
                </ItemCard.Text>
                <ListGroup className="list-group-flush">
                  <FeatPrice>
                    <strong>Ham and Cheese –</strong> lettuce, tomato, onions,
                    mayonnaise and our signature house dressing
                  </FeatPrice>
                  <FeatPrice>
                    <strong>Salami and Cheese –</strong> lettuce, tomato,
                    onions, mayonnaise and our signature house dressing{" "}
                  </FeatPrice>
                  <FeatPrice>
                    <strong>Torino’s Special –</strong> ham, salami, hot
                    peppers, pepperoni, provolone cheese, lettuce, tomato,
                    onions, mayonnaise and our signature house dressing{" "}
                  </FeatPrice>
                  <FeatPrice></FeatPrice>
                </ListGroup>
                <Price>$9.49</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Pasta & Alfredo Sauce </Title>
                <ItemCard.Text>
                  Your choice of pasta with our creamy alfredo sauce
                </ItemCard.Text>
                <Price>$9.99</Price>
              </ItemCard.Body>
            </ItemCard>

            <ItemCard>
              <ItemCard.Body>
                <Title>Gyro & French Fries </Title>
                <ItemCard.Text>Gyro with a side of French Fries</ItemCard.Text>
                <Price>$9.49</Price>
              </ItemCard.Body>
            </ItemCard>
            <ItemCard>
              <ItemCard.Body>
                <Title>Chicken Tenders & French Fries </Title>
                <ItemCard.Text>
                  Basket of Chicken Tenders with a side of French Fries
                </ItemCard.Text>
                <Price>$9.49</Price>
              </ItemCard.Body>
            </ItemCard>
          </TextCol>
        </TextRow>
      </MenuContainer>
    </BackgroundContainer>
  );
}

export default Specials;
