import React from "react";
import { ListGroup, ListGroupItem } from "react-bootstrap";
import MiniHeroSection from "../MiniHeroSection/MiniHeroSection";
import {
    TextRow,
    TextCol,
    MenuContainer,
    BackgroundContainer,
    ItemCard,
    FeatItemCard,
    Header,
    Title,
    Price,
} from "./MenuSection.elements";
import NewImage from "../../../assets/dessert.jpg";
import dessert from "../../../assets/menuPics/dessert.jpg";

// function CardCon(props) {
//     <ItemCard>
//         <ItemCard.Body>
//             <Title>props.Title</Title>
//             <ItemCard.Text>
//                 Our signature garlic butter spread over toasted bread.
//             </ItemCard.Text>
//             <Price>$3.99</Price>
//         </ItemCard.Body>
//     </ItemCard>
// }

function Desserts() {
    return (
        <BackgroundContainer>
            <MenuContainer>
                <Header>Desserts</Header>
                <TextRow>
                    <TextCol>
                        <FeatItemCard>
                            <ItemCard.Body>
                                <Title>Cannoli</Title>
                                <ItemCard.Text>
                                    Homemade italian pastry shell stuffed with
                                    our signature cannoli cream
                                </ItemCard.Text>
                                <Price>$4.99</Price>
                                <FeatItemCard.Img
                                    src={dessert}
                                ></FeatItemCard.Img>
                            </ItemCard.Body>
                        </FeatItemCard>
                    </TextCol>
                    <TextCol>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Cheesecake</Title>
                                <ItemCard.Text>
                                    New york style cheesecake
                                </ItemCard.Text>
                                <Price>$3.99</Price>
                            </ItemCard.Body>
                        </ItemCard>
                        <ItemCard>
                            <ItemCard.Body>
                                <Title>Tiramisu</Title>
                                <ItemCard.Text>
                                    Layers of coffee and brandy soaked sponge
                                    cake
                                </ItemCard.Text>
                                <Price>$5.99</Price>
                            </ItemCard.Body>
                        </ItemCard>
                    </TextCol>
                </TextRow>
            </MenuContainer>
        </BackgroundContainer>
    );
}

export default Desserts;
