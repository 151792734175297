// this is es6 modules nothing specfic from react.
import React from 'react';
// ReactDom.render is in reactDom
import ReactDom from 'react-dom';
// import css
import '../../../App.css';
import {
    Containercall,
    Picbox,
    Textbox,
    Headcall,
    Paracall,
    Buttoncall,
    PicCol,
    RowCall
} from './CallToAction.elements';

function CallingToAction() {

    return(
    
        <Containercall fluid>
               <RowCall>
               <PicCol alt ="etche wood art of torinos pizza.">
                    <Picbox/>
                </PicCol>
                <Textbox>
                    <Headcall>
                    Welcome to Torino’s Pizza
                    </Headcall>
                    <Paracall>
                    Torino’s is a family owned pizza and Italian restaurant serving hand tossed New York Style 
                    pizza and homemade Italian dishes from recipes passed down through the generations.
                    Serving the greater Oviedo area since January 2012, Torino’s prides itself on using the finest ingredients,
                    made from scratch recipes, and casual Italian cuisine that you are certain to love.
                    </Paracall>
                    <Buttoncall href='../../about' alt = "a button to take you to the our story page."> 
                        Our Story
                    </Buttoncall>
                </Textbox>
               </RowCall>
        </Containercall>
    );
}

export default CallingToAction

