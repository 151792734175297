import React from "react";
import { SocialIcon } from "react-social-icons";
import { FooterContainer } from "./Footer.elements";

function Footer() {
  return (
    <FooterContainer className="main-footer">
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            {/* Column 1: PHONE AND HOURS */}
            <div className="col-md-4 col-sm-6">
              <h4 className="title">Phone:</h4>
              <a href="tel:+14076720007" className="content">
                (407) 672-0007
              </a>
              <h4 className="title">Hours: </h4>
              <p className="content">
                <strong>Monday - Saturday:</strong> <br />
                11:00 AM - 9:30 PM
              </p>
              <p className="content">
                <strong>Sunday:</strong> <br />
                12:00 PM - 9:30 PM
              </p>
            </div>
            {/* Column 2: ADDRESS AND SOCIALS */}
            <div className="col-md-4 col-sm-6">
              <h4 className="title">Address:</h4>
              <a
                href="https://goo.gl/maps/zwH5a4g8yQcG1Swp7"
                className="content"
                alt="a google image link that will provide the directions for torino's pizza"
              >
                2871 Clayton Crossing Way <br />
                #1057, Oviedo, Florida 32765
              </a>
              <h4 className="title">Connect with us: </h4>
              <div className="socialHolder">
                <SocialIcon
                  className="social"
                  bgColor="#3E080C"
                  fgColor="#ffffff"
                  url="https://www.facebook.com/torinospizzaoviedo/"
                  network="facebook"
                  alt="a link that will take you to our facebook page."
                />
                <SocialIcon
                  className="social"
                  bgColor="#3E080C"
                  fgColor="#ffffff"
                  url="https://www.yelp.com/biz/torinos-pizza-oviedo"
                  network="yelp"
                  alt="a link that will take you to our Yelp page."
                />
                {/* NEED TO BE DONE */}
                <SocialIcon
                  className="social"
                  bgColor="#3E080C"
                  fgColor="#ffffff"
                  url="mailto:torinos@gmail.com"
                  network="email"
                  alt="an email link to contact us directly."
                />
                {/* SHARE */}
              </div>
            </div>
            {/* Column 3: MAP? */}
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.312289998292!2d-81.26233518491766!3d28.620401082422557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e76f28f05c2c03%3A0x3aa2250853228bbb!2sTorino&#39;s%20Pizza%20%26%20Italian%20restaurant!5e0!3m2!1sen!2sus!4v1605590172642!5m2!1sen!2sus"
                width="100%"
                height="90%"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </div>
          </div>
          {/* Footer Bottom */}
          <center>
            <div className="footer-bottom">
              <p className="text-xs-center">
                &copy; Copyright {new Date().getFullYear()} Torino's Pizza
              </p>
            </div>
          </center>
        </div>
      </div>
    </FooterContainer>
  );
}

export default Footer;
