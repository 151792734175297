import React from 'react';

import{
    Background,
    Shade
    
} from './Euro.elements'

function EuroCenter(){
    return (
        <Background>
        <Shade>
            
        </Shade>
        </Background>

    )

}

export default EuroCenter;